// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../../../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-bio-jsx": () => import("./../../../src/pages/bio.jsx" /* webpackChunkName: "component---src-pages-bio-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-publications-jsx": () => import("./../../../src/pages/publications.jsx" /* webpackChunkName: "component---src-pages-publications-jsx" */),
  "component---src-pages-research-jsx": () => import("./../../../src/pages/research.jsx" /* webpackChunkName: "component---src-pages-research-jsx" */)
}

